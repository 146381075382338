<template>
  <v-contant>
    Cancelled
  </v-contant>
</template>
<script>
export default {
  name: "Cancelled",
  data(){
    return {}
  }
}
</script>
